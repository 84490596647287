.linkItem,
.linkHas-dropdown{
    position: relative;
}
.linkItem a{
    padding: 0.8rem 0rem;
}



.linkItem-subMenu{
    position: absolute;
    background-color: white;
    border-radius: 10px;
    top: 3rem;
    left: -1.5rem;
    display: none;
}


.linkItem:hover .linkItem-subMenu{
    display: block;
}

.linkHas-dropdown .linkItem-subMenu li a{
    padding: 0.5rem 2.5rem 0.6rem 1rem;
    display: block;
    width: 100%;
}